<template>
  <div>
    <h2 class="mb-3">Current Memberships</h2>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="member.current_memberships"
        no-data-text="There are no Current Memberships"
      >
        <template v-slot:item.duration="{ item }">
          {{ item.duration + " " + (item.duration === 1 ? "Month" : "Months") }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            :color="
              item.status === 'Current'
                ? 'success'
                : item.status === 'Future'
                ? 'warning'
                : 'error'
            "
            >{{ item.status }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.membershipForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <h2 class="mt-8 mb-3">Previous Memberships</h2>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="member.past_memberships"
        no-data-text="There are no Previous Memberships"
      >
        <template v-slot:item.duration="{ item }">
          {{ item.duration + " " + (item.duration === 1 ? "Month" : "Months") }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip label small color="error">Past</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.membershipForm.openForm(item, false)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <MembershipForm ref="membershipForm" />
  </div>
</template>

<script>
import MembershipForm from "./components/MembershipForm";

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  components: {
    MembershipForm,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Start Date", value: "formatted_dates.start_date" },
        { text: "End Date", value: "formatted_dates.end_date" },
        { text: "Duration", value: "duration" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      current: [
        {
          id: 1,
          start_date: "1st August 2020",
          end_date: "31st October 2020",
          duration: "3 months",
          status: "Current",
        },
        {
          id: 2,
          start_date: "1st November 2020",
          end_date: "31st October 2021",
          duration: "12 months",
          status: "Future",
        },
      ],
      previous: [
        {
          id: 1,
          start_date: "1st August 2020",
          end_date: "31st October 2020",
          duration: "3 months",
          status: "Historic",
        },
        {
          id: 2,
          start_date: "1st August 2020",
          end_date: "31st October 2020",
          duration: "3 months",
          status: "Historic",
        },
        {
          id: 3,
          start_date: "1st August 2020",
          end_date: "31st October 2020",
          duration: "3 months",
          status: "Historic",
        },
        {
          id: 4,
          start_date: "1st November 2020",
          end_date: "31st October 2021",
          duration: "12 months",
          status: "Historic",
        },
      ],
    };
  },
};
</script>
