<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="closeForm()"
  >
    <v-form @submit.prevent="saveForm" method="post" id="main-details-form">
      <v-card>
        <v-card-title class="headline">Edit Membership</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-menu
            v-model="startDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Start Date"
                v-model="startDateFormatted"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.start_date"
              @input="startDatePicker"
              :max="fields.end_date"
              type="month"
              :showCurrent="false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="endDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="End Date"
                v-model="endDateFormatted"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields.end_date"
              @input="endDatePicker"
              :min="fields.start_date"
              type="month"
              :showCurrent="false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      errors: {},
      fields: {
        start_date: null,
        end_date: null,
      },
      membership: {},
      isCurrentMembership: true,
      startDatePicker: false,
      endDatePicker: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },

  computed: {
    startDateFormatted() {
      const dateParameters = this.fields.start_date.split("-");
      const date = new Date(dateParameters[0], dateParameters[1] - 1);

      return `${this.months[date.getMonth()]} ${date.getFullYear()}`;
    },
    endDateFormatted() {
      const dateParameters = this.fields.end_date.split("-");
      const date = new Date(dateParameters[0], dateParameters[1] - 1);

      return `${this.months[date.getMonth()]} ${date.getFullYear()}`;
    },
  },

  methods: {
    openForm(membership, isCurrentMembership = true) {
      this.dialog = true;
      this.isCurrentMembership = isCurrentMembership;
      this.fields.start_date = membership.start_date.substring(
        0,
        membership.start_date.length - 3
      );
      this.fields.end_date = membership.end_date.substring(
        0,
        membership.end_date.length - 3
      );
      this.membership = membership;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("flightmode/members/saveMembership", {
          appId: this.$route.params.id,
          memberId: this.$route.params.memberId,
          membershipId: this.membership.id,
          fields: this.fields,
          isEditing: true,
          isCurrentMembership: this.isCurrentMembership,
        })
        .then(() => this.closeForm())
        .catch((err) => {
          this.errors = err.response.data.errors;
        });
    },

    closeForm() {
      this.dialog = false;
      this.errors = {};
      this.loading = false;
      this.membership = {};
      this.isCurrentMembership = true;
    },
  },
};
</script>